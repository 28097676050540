import Vue from 'vue'
import store from '@/store'

export const ActivitiesMixin = Vue.extend({
  created: () => {},
  computed: {
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    youthHomeIcon() {
      return store.getters.youthHomeIcon
    },
    activitiesTitle() {
      // @ts-ignore
      const menu = this.getActivityMenu()
      if (menu) {
        return menu.label
      } else {
        return 'Activités'
      }
    },
    activitiesIcon() {
      // @ts-ignore
      const menu = this.getActivityMenu()
      if (menu) {
        return menu.icon
      } else {
        return 'fas fa-chess-knight'
      }
    },
    category() {
      return +(this.$route.query.category || 0)
    },
    activityMenus() {
      return store.getters.config.activityMenus
    },
  },
  methods: {
    getActivityMenu() {
      const menus = store.getters.config.activityMenus.filter(
        (elt: any) => elt.category === this.category)
      if (menus.length === 1) {
        return menus[0]
      }
      return null
    },
  },
})
